export const locationMap = {
  1: "Bengaluru",
};

export const jobCategoryMap = {};

export const Jobs = {
  location: [],
  jobCategory: [],
  Jobs: [
    // {
    //   jobId: "J1",
    //   position: "Senior Computer Vision Engineer",
    //   postedOn: "27-Jun-22",
    //   Responsibilities: [
    //     "Architect, design and build AI-based computer vision algorithms on available traffic data",
    //     "Assist junior computer vision engineers",
    //     "Assist junior computer vision engineers",
    //     "Constantly keep in touch with current trends",
    //     "Test, and deploy the algorithms",
    //   ],
    //   MustHave: [
    //     "3+ years of experience in the following frameworks. Python, PyTorch, TensorFlow, Deep CNN, RNN, LSTM, OpenCV, Machine learning",
    //     "Experience in deploying deep learning models",
    //     "Experience in model optimization",
    //     "Solid foundation in Linear Algebra",
    //   ],
    //   NiceToHave: [
    //     "Knowledge in following frameworks. Vehicle Re-Identification, Person Re-Identification, Action/Activity Recognition, Face Recognition, Object Detection and Object Identification",
    //   ],
    //   Location: 1,
    // },
    // {
    //   jobId: "J2",
    //   position: "Senior Backend Engineer",
    //   postedOn: "27-Jun-22",
    //   Responsibilities: [
    //     "Technically leading a small team of software development engineers",
    //     "Writing code in a highly modular, scalable, maintainable, unit tested platform.",
    //     "Work in a fast-paced, agile, dynamic work environment that has an immediate business impact",
    //   ],
    //   MustHave: [
    //     "BTech, MTech or PhD in Computer Science or related Technical Discipline - preferably from premier Engineering colleges.",
    //     "5-7 years of relevant industry experience",
    //     "Experience in technically leading a small team of highly motivated engineers, in guiding them in understanding of requirements, in architecture/design (high level/low level) of the applications, in best coding and QA practices, in reviewing their software artifacts.",
    //     "Rockstar programmer/coder - should be able to convert requirements/design into code fluently.",
    //     "Strong concept in design principles, patterns",
    //     "Good experience in Linux OS.",
    //     "Solid foundation in computer science, with strong competencies in data structures, algorithms and software design.",
    //     "Penchant for solving complex and interesting problems.",
    //     "Worked in a startup-like environment with high levels of ownership and commitment.",
    //     "Good skills to write unit & integration tests with reasonable coverage of code & interfaces.",
    //   ],
    //   NiceToHave: [
    //     "Knowledge in following frameworks. Vehicle Re-Identification, Person Re-Identification, Action/Activity Recognition, Face Recognition, Object Detection and Object Identification",
    //     "Experience in Python, Python frameworks and libraries, and NodeJS",
    //   ],
    //   Location: 1,
    // },
    // {
    //     jobId: "J3",
    //     position: "DevOps Engineer",
    //     postedOn: "27-Jun-22",
    //     Responsibilities: [
    //       "Automated configuration and management of infrastructure.",
    //       "Continuous integration and delivery of distributed systems at scale in an Hybrid environment.",
    //     ],
    //     MustHave: [
    //       "3-4 years of experience in DevOps.",
    //       "Experience in managing IT Infrastructure at scale.",
    //       "Experience in automation of deployment of distributed systems and in infrastructure provisioning at scale.",
    //       "Hands on experience on Linux and Linux based systems, Linux scripting.",
    //       "Experience in Server hardware, Networking, firewalls.",
    //       "Experience in source code management, configuration management, continuous integration, continuous testing, continuous monitoring.",
    //       "Experience with CI/CD and related tools.",
    //       "Experience with containerization, container orchestration, management.",
    //     ],
    //     NiceToHave: [
    //       "Penchant for solving complex and interesting problems",
    //       "Worked in a startup-like environment with high levels of ownership and commitment.",
    //       "BTech, MTech or PhD in Computer Science or related Technical Discipline."
    //     ],
    //     Location: 1,
    //   },
    // {
    //   jobId: "J4",
    //   position: "Business Development Associate",
    //   postedOn: "27-Jun-22",
    //   Responsibilities: [
    //     "Understanding the technical working of Beltech AI’s products.",
    //     "Find out tenders which are either being floated or already floated related to Beltech AI’s products.",
    //     "Identifying and developing new business opportunities.",
    //     "Working closely with the senior management team to ensure that all stakeholders are informed of the progress of the sales team.",
    //     "Creating proposals and presentation material.",
    //     "Working closely with the Human Resources Officer and assisting them to ensure team engagement and motivation.",
    //     "Manage the process of developing a product or enhancing existing products in order to meet customer expectations effectively.",
    //   ],
    //   MustHave: [
    //       "Energetic & enthusiastic.",
    //       "Ability to overcome objections and manage time effectively.",
    //       "Excellent oral and written communication skills.",
    //       "Ability to build and maintain relationships.",
    //       "Strong presentation skills.",
    //       "Ability to quickly learn and use new technology.",
    //       "Excellent time management and organizational skills."
    //   ],
    //   NiceToHave: [
    //       "Lead the team like a conductor leads the orchestra.",
    //       "High capacity to collaborate effectively and facilitate collaborations between teams.",
    //       "High level of situational awareness and EQ."
    //   ],
    //   Location: 1,
    // },
    // {
    //   jobId: "J5",
    //   position: "Executive Assistant",
    //   postedOn: "27-Jun-22",
    //   Responsibilities: [
    //       "Review documents.",
    //       "Accounting/bookkeeping.",
    //       "Calendar management",
    //       "Client relations.",
    //       "Design and maintain a filing system.",
    //       "Handle information requests.",
    //       "Organize meetings.",
    //       "Keep records.",
    //       "Arrange travel plans.",
    //       "Event planning.",
    //       "Prepare statistical reports.",
    //       "Processing expense reports.",
    //       "Take meeting minutes."
    //   ],
    //   MustHave: [
    //       "Bachelor’s Degree in related discipline.",
    //       "Willing to travel",
    //   ],
    //   NiceToHave: [
    //       "Experience working with Google Drive and related technologies."
    //   ],
    //   Location: 1,
    // },
  ],
};
